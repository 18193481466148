/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { getIndexedProduct } from 'Util/Product';

import {
    TOGGLE_GLOBAL_PRODUCT_LOADING,
    UPDATE_PRODUCT_DETAILS,
    UPDATE_PRODUCT_DETAILS_LOADING_STATUS
} from './Product.action';

/** @namespace Store/Product/Reducer/getInitialState */
export const getInitialState = () => ({
    product: {},
    formattedConfigurableOptions: {},
    isLoading: true,
    isGlobalProductLoading: false
});

/** @namespace Store/Product/Reducer/formatConfigurableOptions */
export const formatConfigurableOptions = (configurable_options) => configurable_options
    .reduce((prev, option) => {
        const {
            attribute_id,
            label,
            attribute_code
        } = option;

        return {
            ...prev,
            [attribute_code]: {
                attribute_id,
                label
            }
        };
    }, {});

/** @namespace Store/Product/Reducer */
export const ProductReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case UPDATE_PRODUCT_DETAILS:
        const { product } = action;

        return {
            ...state,
            isGlobalProductLoading: false,
            product: getIndexedProduct(product)
        };

    case UPDATE_PRODUCT_DETAILS_LOADING_STATUS:
        const { status } = action;

        return {
            ...state,
            isLoading: status
        };

    case TOGGLE_GLOBAL_PRODUCT_LOADING:
        return {
            ...state,
            isGlobalProductLoading: action.payload
        };

    default:
        return state;
    }
};

export default ProductReducer;
